:root {
  --transitionTime: 0.5s;
  --borderRadius: 4px;
  --extraRoundBorder: 10px;
  --backgroundColor: #202020;
  --darkBlue: #192637;
  --blue2: #1053aa;
  --maxPageWidth: 1200px;
  --redText: #aa0000;
  --grayBorder: #323232;
  --fadedGray: #242424;
  --maxWidthNarrow: 600px;
  --loveRed: #eb5757;
  --softBlue: #397ce1;
  --mobileMaxSize: 991px;
  --bgColorMain: #000;
  --deepGray: #333333;
  --inactiveIconGray: #808080;
}

body {
  background-color: var(--bgColorMain);
  color: white;
}

.h1, h1{
	font-size:1.6rem;
}

.h2, h2 {
    font-size: 1.25rem;
}

.h3, h3 {
    font-size: 1.15rem;
}

.h4, h4 {
    font-size: 1.1rem;
}

.h5, h5 {
    font-size: 1.05rem;
}

.h6, h6 {
    font-size: 1rem;
}

.icon.heartIcon.active{
    color: var(--loveRed);
}

.modal-body{
	padding: 10px;
}

span.highlight {
    border-bottom: 3px solid yellow;
}

div#show-convo-full {
    max-height: 400px;
    overflow: auto;
}

span.header {
    font-size: 1.2em;
    padding: 0px 10px;
    width: 50%;
    display: inline-block;
}

button.softBlue{
	background: var(--softBlue);
	color:#FFF;
	border: none;
}

.bottomNavBar .icon.homeIcon{
	height: 36px;
    width: auto;
}

.bottomNavBar .icon.searchIcon {
  height: 24px;
  width: auto;
  margin-bottom:2px;
  color:var(--inactiveIconGray);
}

.removeScrollParent {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

span.taggedUser a {
    color: #2986ff;
    font-weight: 500;
}

.removeScrollChild {
  height: 100%;
  margin-right: -50px; /* Maximum width of scrollbar */
  padding-right: 50px; /* Maximum width of scrollbar */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

.totalItemCount{
	display:none;
}

.favoriteItemCount{
	margin:4px 12px;
}

/*profile header display*/
.profile-pic-section {
    position: relative;
	  display: flex;
    justify-content: center;
}

.profile-pic-section .country-img {
    position: relative;
    top: 50px;
    right: 28px;
	height:30px;
    z-index: 1;
    margin-right: -25px;
	width:25px;
}

.profile-pic-section .country-img img{
	width:25px;
}

.profile-info-section {
    flex: 1;
    display: flex;
	align-items: center;
	overflow: hidden;
}

.profile-info-section *{
	text-overflow:ellipsis;
	overflow: hidden;
}

.transactions{
	font-size:.9rem;
}

.profile-main .following-button {
    min-height: 34px;
}

.profile-col {
    display: flex;
    flex-direction: column;
	justify-content: space-between;
    height: 100%;
}

.profile-col.profile-mid{
	flex:1;
	margin-left: 14px;
	overflow: hidden;
}

.profile-col.profile-right{
	align-items: flex-end;
}

.profile-info-section .username{
    font-size: 1.2rem;
    font-weight: 500;
	white-space:nowrap;
}

.profile-info-section .following{
	font-size: .9rem;
}

.profile-info-section .following-button .roundedButton.veryShort {
	margin: 0px 0px 8px 0px;
    padding: 2px 10px;
    min-width: 88px;
}


.profile-main{
	display: flex;
    flex-direction: row;
	margin-bottom:30px;
}

/* Hide scrollbar for IE, Edge and Firefox */
/* body {
  -ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none;  Firefox */
/* } */

/* .infinite-scroll-component__outerdiv::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar,
.homePage::-webkit-scrollbar, .root::-webkit-scrollbar,
.backgroundImage::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

html {
overflow: scroll;
overflow-x: hidden;
}
::-webkit-scrollbar { */
/* width: 0px; remove scrollbar space */
/* background: transparent; */
/* } */
 /* */


a, a:hover {
  color: white;
}

button {
  background-color: rgba(0, 0, 0, 0.1);
  border: solid 1px #444;
  border-radius: var(--borderRadius);
  color: #ccc;
  padding: 6px 15px 7px 15px;
  margin: 4px 5px 4px 5px;
  transition: background-color var(--transitionTime),
              border-color var(--transitionTime),
              color var(--transitionTime);
}


button:focus {
  outline: none;
}

hr {
  border-color: grey;
  width: 100%;
}

textarea {
  min-height: 35px;
  max-height: 200px;
  color:#FFF;
}


/* Multiple pages */

.displayNone {
  display: none;
}

.displayFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.margin0 {
  margin: 0;
}

.padding0 {
  padding: 0;
}


.positionVerticalCenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.positionRelative {
  position: relative;
}

.cursorPointer {
  cursor: pointer;
}


.bottomNavBarSection2 {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  bottom: 64px;

  /* using css */
  /*
  background-color: black;
  border-radius: 25px 25px 0 0;
  padding: 13px 13px 0px 13px;
  width: 135px;
  height: 45px;
  */

  /* using image */
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-size: contain;
  padding: 8px 27px 0px 24px;
  width: 140px;
  height: 45px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2000;
}
.bottomNavBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: black;
  min-height: 65px;

  display: flex;
  justify-content: space-around;
  padding: 0px 10px 0px 10px;
  align-items: center;
  z-index: 2000;
}

.bottomNavBar .profilePictureBackground {
  width: 32px;
  height: 32px;
}


.bottomNavBar .profilePicture {
	width: 100%;
  height: 100%;
  border: none;
}

.bottomNavBar .profilePicture.defaultProfilePicture {
  /* padding: 4px; */
  object-fit: contain;
  width: 18px;
  height: 18px;
  /* position: relative;
  objectFit: contain; */
  margin: 5px;
}

.bottomNavBar a img{
	width: auto;
    height: 23px;
}

.bottomNavBar a img.exploreIcon{
	margin-bottom:3px;
}

.bottomNavBar a img.feedIcon, .bottomNavBar a img.newItemIcon{
	height: 34px;
}

.searchBar .icon {
  width: 18px;
  height: 18px;
}

.searchBar .icon.heartIcon{
	width:24px;
	height:24px;
}

.icon.filterIcon.active{
	fill:currentColor;
}

.bottomNavBar .navButton {
    min-width: 35px;
	display: flex;
    justify-content: center;
}

.icon {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.icon.heartIcon {
  width: 1.2rem;
  height: 1.2rem;
}

.container {
  color: white;
}

.navSection2 .heartButton {
  margin-right: 10px;
}

.inputLabel {
  font-size: 1.15rem;
}

.centerText {
  text-align: center;
}

.showNewLines {
  white-space: pre-line;
}

/*
.squareButton.active {
  background-color: white;
}

.squareButton {
  background-color: darkgrey;
}*/

.profilePictureBackground {
  border-radius: 300px;
}

.profilePictureInnerBackground {
  background: #202020;
  border-radius: 100px;
  position: relative;
}

.profilePicture.defaultProfilePicture {
  border-radius: 0;
}
.profilePicture {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 300px;
  margin-right: 20px;
  /* border: solid 2px white; */
  background-color: #202020;
}
.profilePicture.small {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 0;
}

.mini-image .adminImage{
	width:100%;
	height:100%;
}

.mini-image{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
	margin:10px 0px;
}

.mini-image div{
	font-weight:600;
	padding-bottom:10px;
	font-size:.85em;
}

.center-flex{
	display: flex;
    justify-content: center;
    align-items: center;
}

.userSnippet {
  margin:0px 0;
}

.userSnippet .ratingSection .icon {
  height: 16px;
  width: 16px;
}

.userSnippet .ratingSection {
	display: flex;
    align-items: center;
    text-align: center;
	font-size: .9rem;
}

.userSnippet .ratingSection .numRate{
	margin-left:2px;
}


.ratingSection a:hover .starIcon {
  color: white;
}

.alert {
  position: fixed;
  top: 30px;
  left: 30px;
  right: 30px;
  text-align: center;
  z-index: 5000;
}

.paymentMethod {
  border: solid 1px #666;
  border-radius: var(--borderRadius);
  padding: 10px;
  margin-bottom: 10px;
}

.cartPage .paymentMethod {
  cursor: pointer;
}

.paymentMethod.active {
  border: solid 2px #aaa;
}

.shippingAddress {
  border: solid 1px #666;
  border-radius: var(--borderRadius);
  padding: 10px;
  margin-bottom: 10px;
}


.cartPage .shippingAddress {
  cursor: pointer;
}

.shippingAddress.active {
  border: solid 2px #aaa;
}

.cartPage .overlayEditButtons {
  margin: 0;
}


.paypalButtonContainer {
  text-align: center;
}


.modal .close {
  color: white;
}

.modal-content {
  background-color: #202020;
}

.modal-header, .modal-footer {
  border-color: #101010;
}

.modal {
  z-index: 2500;
}

.mutedText {
  color: grey;
}

.textButton {
  cursor: pointer;
}

.noUnderline, .noUnderline:hover {
  text-decoration: none;
}

.userSelectNone {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.stickToBottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.stickToTop {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
}

table button {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: black
}

.roundedButton {
  width: 100%;
  background: linear-gradient(
                      to right,
                      #444,
                      white 40%,
                      white 60%,
                      #444 100%
                    );
  color: black;
  font-weight: 500;
  /* border-radius: 100px; */
  border-radius: var(--borderRadius);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gradientBackground {
  background: linear-gradient(
                      to right,
                      #444,
                      white 40%,
                      white 60%,
                      #444 100%
                    );
  color: black;
}

.width100 {
  width: 100%;
}

.displayInlineBlock {
  display: inline-block;
}

.boldText {
  font-weight: bold;
}

.spaceBetween {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.loading {
  width: 100%;
  color: grey;
  text-align: center;
  font-size: 2rem;
  margin-top: 30px;
}

.marginLeft0 {
  margin-left: 0;
}

.seeMoreText {
  font-weight: bold;
}
.seeMoreReplacementSpacer {
  height: 1.7rem;
}

.hiddenLinks {
  opacity: 0;
}




/* Initial Page */
.initialPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(99vh - 160px);
}

.largeTitleLogo {
  width: 100%;
}

.initialNavbar {
    justify-content: space-between;
    width: calc(100% - 20px);
    height: 50px;
    display: flex;
    align-items: center;
}

.initialNavbar.displayNone {
  display: none;
}


.initialNavbar .initialNavbarRightButtons {
  display: flex;
  justify-content: flex-end;
}

.initialPage a {
    width: 100%;
    display: flex;
    justify-content: center;
}

.LButton {
	border-radius: var(--borderRadius);
	width: 100%;
    max-width: 300px;
    margin: 0px 40px;
}

.whiteButton{
	background: white;
    color: black;
}

a:hover {
	text-decoration: none;
}

.whiteButton:active{
	background: var(--deepGray);
}

/* Navbar */
.NavLink {
  margin-left: 20px;
  cursor: pointer;
  transition: color var(--transitionTime);
}

.NavLink:hover {
  color: #ffffff;
}

.navbar {
  position: sticky;
  top: 0;
	background-color: var(--bgColorMain);
  z-index: 2000;
  justify-content: center;
	padding: 10px;
}

.navbar-toggler {
  border: none;
  outline: none;
  background-color: rgba(0,0,0,0);
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.mainNavBar .FDLogoSmall, .initialNavbar .FDLogoSmall,
.mainNavBar .smallTitleLogo, .initialNavbar .smallTitleLogo {
  height: 40px;
  width: 100px;
  object-fit: contain;
}

.navbarIconContainer {
	display: flex;
  /* flex: 1; */
  justify-content: flex-end;
  align-items: center;
}


.navbarIconContainer .iconContainer {
  position: relative;
  margin: 0px 3px;
}

.navbarIconContainer .iconContainer .iconCounter {
	position: absolute;
    top: -4px;
    right: -6px;
    background-color: #ff3030;
    border-radius: 20px;
    min-width: 18px;
    min-height: 18px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
}

.navbarIconContainer .icon {
	width: 22px;
  height: auto;
  margin: 0px 6px;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("./images/svg/menu_white.svg");
  height: 1rem;
  width: 1rem;
}

.loadingPage .navbar, .errorPage .navbar {
  justify-content: center;
}

/* .navHideOnMobile {
   display: block;
}

.navShowOnMobile {
   display: block;
} */

.navbarContainer {
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-width: var(--maxPageWidth);
}

.navbarContainer.navbarContainerMobile > *{
	min-width: 100px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.navbarContainer.navbarContainerMobile .navbar-toggler {
  justify-content: flex-start;
}


.navbarContainer.navbarContainerMobile .navbarIconContainer {
  justify-content: flex-end;
}

.navbar .bellIcon {
  margin-right: 0;
}

/*END NAV BAR*/

.favorite-item-corner{
	padding-left:10px;
}

.favorite-item-corner svg, .likeItemBox svg{
	width:20px;
	height: 20px;
}
.itemLikerContainer{
	font-size:.7rem;
	text-transform: uppercase;
	display:flex;
	justify-content: flex-end;
    align-items: center;
	margin:0;
	margin-right:10px;
}
.itemLikers .box{
	padding:4px 16px;
	border: 1px solid var(--deepGray);
	color: #ccc;
}

.forceHide{
	display:none !important;
}

.single-image-item{
	display:flex;
	justify-content:center;
	align-items:center;
	margin:20px 0px 30px 0px;
}

.single-image-item .itemImage.onlyImage{
	max-width:300px;
	max-height:300px;
}

.shareBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.shareBox .friend_button, .shareBox .feed_button{
	display:none;
}

.shareBox .label{
	padding-right: 4px;
}

.shareBox button{
	padding:2px;
	font-size:.7rem;
	padding:4px 16px;
	border-radius: 0;
	text-transform: uppercase;
}

.belowItemImagesRow{
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content: space-between;
    margin: 0px 10px;
}

.blackButton{
	background:var(--bgColorMain);
	color: var(--inactiveIconGray);
}

.offerContainer .offerBox {
    display: flex;
	align-items: center;
    justify-content: center;;
}

.offerContainer .nice-gray-box{
	background-color: var(--deepGray);
	display: flex;
    align-items: center;
    justify-content: center;
	border-radius: var(--borderRadius);
	padding: 10px 20px;
}

.offerContainer{
	margin:30px 20px 30px 20px;
}

.hide-me{
	display:none !important;
}

.row.row-no-flex{
	display:block;
}

.niceDisplayBackend .title label{
	margin:2px 10px 10px 0px;
}

.userRowMobile .title{
	font-size:1.3em;
	cursor: pointer;
}

.otherFields {
	margin:10px;
}

.otherFields span.label {
	font-weight: 500;
    font-size: 0.75em;
    padding-top: 2px;
	line-height: 1em;
    margin-right: 10px;
}

.otherFields .row {
	align-items:center;
	margin-right:0;
	margin-left:0;
}

.offerContainer .currencyShower{
	margin:0px 10px;
}

.admin-header{
	display: block;
	margin:10px 0px;
}

.admin-title{
	font-size:1.5em;
	margin:2px 15px 2px 0px;
}

.offerContainer .LInput{
    border-left-width: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
	width: 150px;
}

input.searchbox{
	min-width:250px;
}

input.LInput.highlightUnder {
    border-color: var(--inactiveIconGray);
    outline: none;
}

.rowItem {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hollowBox{
	border: 1px solid var(--deepGray);
	border-radius: var(--borderRadius);
	min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    margin: 10px 0px;
}

.offerContainer .offerBox button{
	margin: 0;
    margin-left: 25px;
}

.searchBarIconContainer .icon {
  margin: 0 6px;
}

.searchBarIconContainer {
    display: flex;
    align-items: center;
}

.grecaptcha-badge {
  display: none;
}

.timeStamp{
	font-size:.8rem;
}

.LInput, input {
  background: none;
  border: 1px solid #222222;
  color:#FFF;
  outline:none;
  padding: 0.375rem 0.75rem;
}

.LInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.LInput, .LInput:active, .LInput:focus {
  outline: none;
}

.splashButtons a{
	width:100%;
	margin-top:15px;
}

.frontPagePic{
	flex:1;
	margin:0px;
}

.splashButtons{
	flex:1;
	display:flex;
	width:100%;
	align-items:center;
	justify-content:flex-start;
	margin-top:0px;
	flex-direction:column;
}

.logoMed{
    width: 50%;
    margin: 50px auto;
    display: block;
}

.joinUsPage .largeTitleLogo {
  margin: 100px 0;
  width: 100%;
}

.joinUsPage .LInput {
    margin: 15px 5px;
    padding: 0.375rem 0.75rem;
    width: 100%;
}

.LInput::placeholder{
	color:gray;
}

.LDropdown .dropdown-toggle {
  background: none;
  border: 1px solid #222222;
  color: gray;
  width: 100%;
  text-align: left;
}
.LDropdown .dropdown-toggle:active, .LDropdown .dropdown-toggle:hover,
.LDropdown .dropdown-toggle:focus, .LDropdown .dropdown-toggle,
.LDropdown .show>.btn-dark.dropdown-toggle
{
  box-shadow: none !important;
  outline: none;
}


.LDropdown .dropdown-toggle::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown-menu {
  background-color: black;
  min-width: 6rem;
}
.dropdown-menu .dropdown-item {
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
  text-align: center;
  color: white;
  transition: color var(--transitionTime),
                background-color var(--transitionTime),
                border-color var(--transitionTime);
}

.container .dropdown .dropdown-menu a:hover,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus
{
  color: black;
  background-color: white;
  border-color: black;
}


/* sign in page */
.defaultPage.signInPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  /* width: calc(100vw - 80px); */
  max-width: var(--maxWidthNarrow);
}


.centerUI{
	display: flex;
    justify-content: center;
    align-self: center;
    margin: 0;
}

.signInPage .largeTitleLogo {
  margin: 100px 0;
  width: 100%;
}

.signInPage .LInput {
  margin: 0px 0px 20px 0px;
  height: calc(1.5rem + .75rem + 2px);
  padding: .375rem .75rem;
  width: 90%;
}

.signInPage button, .joinUsPage button {
  margin-left: 0;
}




/* Add New Item */
.addNewItemPage .mainContent {
  padding-top: 20px;
}

.addNewItemPage .dropdown-menu.show
.dropdown-item:last-child {
  /* make sure last item is visible */
  margin-bottom: 60px;
}

.brandButtonContainer {
  text-align: center;
}


.imageUploadBox, .emptyImageUploadBox {
  width: 120px;
  height: 120px;
  border: 1px dashed #3a3a3a;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}

.imageUploadBox {
  object-fit: cover;
}

.emptyImageUploadBox {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.emptyImageUploadBox .addPhotoCluster {
  text-align: center;
  font-size: 1rem;
}

.addPhotoCluster .photoIcon{
	width: 20px;
	height: 20px;
	margin-bottom:4px;
}

.dragging .emptyImageUploadBox {
  border-color: green;
}
.confirmDisplayImage {
  width: calc(100% - 10px);
  height: 75%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}
.react-multi-carousel-item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

/* remove number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.flexBreak {
  flex-basis: 100%;
  height: 0;
}

.squareButton, .squareButton:active, .squareButton:focus {
  background: rgba(0, 0, 0, 0);
  color: #ddd;
  border-radius: 0;
  transition: all var(--transitionTime);
  margin-right: 10px;
  margin-left: 0px;
}

.squareButton.active {
  background: #FFF;
  color: #222;
}

.addNewItemPage .LInput {
  padding: 7px 0 7px 7px;
  background-color: black;
  width: calc(100% - 60px);
  max-width: 700px;
  margin: auto;
  display: block;
}
.addNewItemPage .LInput::placeholder {
  opacity: 0.5;
}


.roundedButton.short {
  width: auto;
  padding: 3px;
  padding-right: 27px;
  padding-left: 27px;
  /*
  background: linear-gradient(
                      to bottom,
                      #999,
                      #fff 50%,
                      #999 100%
                    ); */
   background:#FFF;
  color: #222;
  border: solid 1px #ccc;
}



.sellerStoreNavBar button{
	border-radius:0px;
}

.sellerStoreNavBar a:nth-child(1) button.roundedButton{
	border-top-left-radius:var(--borderRadius);
	border-bottom-left-radius:var(--borderRadius);
}

.sellerStoreNavBar button.rightRounded, .sellerStoreNavBar a:last-child button.roundedButton{
	border-top-right-radius:var(--borderRadius);
	border-bottom-right-radius:var(--borderRadius);
}

.roundedButton.veryShort {
  width: auto;
  padding: 6px 16px;
  background:#FFF;
  color: #222;
  border: 1px solid #2e2e2e;
  font-size: 0.9rem;
}

.addNewItemPage .belowCarousel {
  width: calc(100% - 60px);
  margin: auto;
  max-width: 700px;
}

.priceInput {
  width: 120px;
  background: none;
  border-radius: 0;
  border-width: 2px;

  border-image:
    linear-gradient(
      to right,
      #444,
      #bbb 50%,
      #444 100%
    ) 1;

  border-top: none;
  border-right: none;
  border-left: none;
  color: white;
  text-align: right;
  outline: none;
}

.plusContainer {
  /* border: solid 1px white; */
  border-radius: 1000px;
  height: 27px;
  width: 27px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
}

.addNewItemPage .overlayEditButtons .icon {
  width: 25px;
  height: 25px;
}

/* Home */

.homePage {
  padding-left: 5px;
  padding-right: 5px;
}

.allhomeItemsContainer {
	width:100%;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.allhomeItemsContainer::-webkit-scrollbar {
  display: none;
}


.homeItem {
  color: #ddd;
  transition: color var(--transitionTime);
  flex: 0.5;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.homeItem a {
  color: #ddd;
  text-decoration: none;
}
.homeItem:hover, .homeItem:hover a {
  text-decoration: none;
  color: white;
}

.viewSwitchContainer {
    display: flex;
    justify-content: flex-end;
	align-items:center;
	margin:10px 0px;
	color: var(--gray);
}

.viewSwitchContainer .spacer{
    flex: 1;
}

.viewSwitchContainer .viewSwitchItem.active{
	color:#FFF;
}

.viewSwitchContainer .viewSwitchItem{
	margin:0px 10px;
}

.homeBottomRow .secondItem{
	min-width:25px;
	text-align:center;
}

.homeImageDisplay {
  width: 100%;
  object-fit: cover;
  position: relative;
  left: 0px;
  top: 0px;
  transition: width var(--transitionTime),
              height var(--transitionTime),
              left var(--transitionTime),
              top var(--transitionTime);
  border-radius: var(--borderRadius);
  margin-bottom: 15px;
}

.homeItemLineOne, .homeItemLineTwo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.95rem;
  align-items:center;
}

.homePage .navSection2 {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.itemBrandLabel {
  font-size: 0.95rem;
  margin:0;
}

/* .homePage .scrollTarget {
  position: relative;
  top: -80px;
} */

/* Item */

.itemPage .sizeButton, .itemPage .sizeButton:active, .itemPage .sizeButton:focus {
  background: rgba(0, 0, 0, 0);
  color: #ddd;
  border-radius: 0;
  border: solid 2px grey;
  transition: all var(--transitionTime);
  /* padding: 1px 0 0 0;
  width: 2.4rem;
  font-size: 0.85rem; */

}

.follower-area {
    margin: 0px 10px;
}

.itemPage .sizeButton.active {
  background-color: white;
  color: #222;
  border-color: white;
}

.userSnippetWrapper {
  margin: 0px 10px;
}

.itemHeader .price{
	font-size: 1.25rem;
}

.itemInfoContainer .iteminfo1{
	justify-content: normal;
    align-items: center;
}

.itemInfoContainer .iteminfo1 .item-buffer{
	padding-right: 10px;
}

.itemInfoContainer .tabRow{
	font-size: 0.9rem;
}

.shadow-inset{
    box-shadow: inset 0 3px 9px -7px rgb(165 165 165 / 40%);
    display: block;
    height: 8px;
    width: 100%;
	padding-bottom:10px;
	margin-top:-1px;
}

.tabBar button.active{
	border-bottom: 1px solid white;
}

.tabBar button{
	border:none;
	border-bottom: 1px solid transparent;
	flex:1;
	border-radius: 0px;
    background: none;
    margin: 0;
}

.productTabToggle{
	flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
	padding-bottom: 15px;
	border-bottom: 1px solid transparent;
}

.productTabToggle.selectedTab{
	border-bottom: 1px solid #FFF;
}

.itemInfoContainer .iteminfo1 .item-buffer::after {
    content: "-";
    margin-left: 10px;
}

.itemInfoContainer {
  padding: 10px;
  border-radius: var(--borderRadius);
}

.sizingDropdown {
  display: inline-block;
}


/* Explore */
.allExploreItemsContainer {
  display: block;
  margin-left: 1px;
	overflow:hidden !important;
}

.exploreItem {
  color: #ddd;
  transition: color var(--transitionTime);
  /*flex: 0.5;*/
  display: inline-block;
  width: calc(33.333vw - 3px);
  height: calc(33.333vw - 3px);
  margin: 1px;
  justify-content: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  max-width: calc(33.333vw - 3px);
}

.large .exploreItem {
  max-width: calc(66.666vw - 4px);
}
.exploreItem.floatRight {
  float: right;
  position: relative;
  right: 2px;
}
.exploreItem.floatLeft {
  position: relative;
  float: left;
  right: 0;
}


.exploreImageDisplay {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*position: relative;*/
  left: 0px;
  top: 0px;
  transition: width var(--transitionTime),
              height var(--transitionTime),
              left var(--transitionTime),
              top var(--transitionTime);
  border-radius: 0;
  margin-bottom: 10px;
}




.exploreItem .exploreItemContainer{
  margin-bottom: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}


/* posts in explore page */

.explorePost {
  color: #ddd;
  transition: color var(--transitionTime);
  /*flex: 0.5;*/
  display: inline-block;
  width: calc(33.333vw - 3px);
  height: calc(33.333vw - 3px);
  margin: 1px;
  justify-content: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.explorePost.large {
  width: calc(66.666vw - 4px);
  height: calc(66.66vw - 4px);
}
.explorePost.floatRight {
  float: right;
  position: relative;
  right: 2px;
}
.explorePost.floatLeft {
  position: relative;
  float: left;
  right: 0;
}

.explorePost .explorePostContainer{
  margin-bottom: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}


.explorePost .explorePostContainer a,
.explorePost .explorePostContainer .linkHolder {
  width: 100%;
  height: 100%;
}


.explorePage .explorePost {
	flex:1;
	display: inline-flex;
	margin:0px 3px 3px 0px;
	position: relative;
	cursor:pointer;
	width: auto;
    height: auto;
}

.exploreTextDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


.homeImageDisplayDiv{
	border-radius: var(--borderRadius);
}

.homePage .searchBar{
    max-width: var(--maxWidthNarrow);
	width:100%;
	margin: 10px auto 20px;
}

.homeItem .homeItemContainer {
  cursor: pointer;
  /*
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-bottom: 100%;
  position:relative;*/
}
/*
.homeBottomRow {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: #0000008f;
    padding: 5px 0px;
    width: 100%;
}
*/

.homeItem a.linkHolder, .homeImageDisplayDiv{
	height: 100%;
  width: 100%;
  padding-bottom: 100%;
}

.exploreItem .exploreItemContainer a,
.exploreItem .exploreItemContainer .linkHolder {
  width: 100%;
  height: 100%;
}


/* .explorePage .scrollTarget {
  position: relative;
  top: -20px;
} */

/* Search bar */
.searchBar {
	width: auto;
    color:#c3c3c3;
    border-radius: var(--borderRadius);
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding: 4px 4px 4px 4px;
	align-items: center;
    background-color: var(--grayBorder);
}

.searchBar input {
	background: none;
    border: none;
    outline: none;
    color: #c3c3c3;
    flex: 1;
	min-width:10px;
  /* user-select: none; */
}

.searchBar input::-webkit-search-cancel-button{
  display: none;
}

/* Item Page */
.itemImage {
  width: calc(100% - 10px);
  height: 75%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.imageModal .modal-dialog {
  width: 100%;
  margin: auto;
  height: 100%;
  max-width:100%;
}

.imageModal .modal-body{
	padding:0;
	display:flex;
	justify-content: center;
    align-items: center;
}

.imageModal .modal-body .close{
    position: absolute;
	background: #000;
    top: 0;
    right: 0;
    margin: 5px !important;
    padding: 20px;
    font-size: 38px;
    border-radius: 100px;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.imageModal .modal-dialog .modal-content{
	height:100%;
}

.imageModal img {
  width: 100%;
  max-height: 95vh;
  object-fit: contain;
}

.carouselImageLink {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.itemImage.onlyImage {
  width: 60vw;
  height: 60vw;
  max-width: 500px;
  max-height: 500px;
}
.react-multi-carousel-item--active {
  width: 300px;
}

.roundedButton.clear, .roundedButton.short.clear,
.roundedButton.veryShort.clear {
  background: none;
  border-color: #444;
  color: white;
  border-width: 1px;
}

.sellerStoreNavBar {
  display: flex;
  justify-content: space-between;
}

.toggleShowAll.active button{
	background: #FFF;
    color: var(--deepGray);
}

.sellerStoreNavBar .roundedButton.short, .sellerStoreNavBar a {
  flex: 1 1;
  margin: 0 !important;
}

.sellerStoreNavBar .roundedButton.short{
	border-color: var(--grayBorder);
	padding: 8px 2px;
}

.sellerStoreNavBar a .roundedButton.short {
  min-width: 100%;
}


/* User Store  */

.userStoreItemPage {
  padding-left: 5px;
  padding-right: 5px;
}

.userStoreItem .userStoreItemContainer {
  position: relative;
  cursor: pointer;
  width: calc(100% - 6px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--borderRadius);

}


.userStoreItemList {

}

.userStoreItem {
  color: #ddd;
  transition: color var(--transitionTime);
  flex: 0.5;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.userStoreItemImage {
  width: calc(49vw - 25px);
  height: calc(49vw - 25px);
  object-fit: cover;
  position: relative;
  left: 0px;
  top: 0px;
  transition: width var(--transitionTime),
              height var(--transitionTime),
              left var(--transitionTime),
              top var(--transitionTime);
  border-radius: var(--borderRadius);
  margin-bottom: 10px;
}

.homeItemLineOne{
	margin-bottom:6px;
}

.homeItemLineOne, .homeItemLineTwo, .manageItemLineOne, .manageItemLineTwo{
  text-align: left;
  font-size: 0.95rem;
  padding: 0px 10px;
}

.homeItemLineTwo, .manageItemLineTwo {
  display: flex;
  justify-content: space-between;
}

.userStoreItemWarning {
  color: var(--redText);
  text-align: center;
}

/* .userStoreItem .bottomSection {
  position: absolute;
  bottom: 7px;
  padding: 0 7px;
} */


/* Manage Items */

.manageItemsPage {
  padding-left: 5px;
  padding-right: 5px;
}


.manageItem .manageItemContainer {
  position: relative;
  margin: 14px;
  cursor: pointer;
  width: calc(100% - 6px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--borderRadius);
}

.manageItemsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.manageItem {
  color: #ddd;
  transition: color var(--transitionTime);
  flex: 0.5;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.manageItemsImage {
  width: calc(49vw - 30px);
  height: calc(49vw - 30px);
  object-fit: cover;
  position: relative;
  left: 0px;
  top: 0px;
  transition: width var(--transitionTime),
              height var(--transitionTime),
              left var(--transitionTime),
              top var(--transitionTime);
  border-radius: var(--borderRadius);
  margin-bottom: 10px;
}

.manageItemWarning {
  color: var(--redText);
  text-align: center;
}

.overlayEditButtons {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  margin: 10px 0px 0px 0px;
}

.overlayEditButtons .editButton, .overlayEditButtons .deleteButton,
.overlayEditButtons .rightEditButton {
  background-color: black;
  border-radius: 200px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px white;
}

.overlayEditButtons .editButton {
  margin-right: 5px;
}

.manageIconContainer .icon {
  width: 22px;
  height: 22px;
}

.itemPage .itemEditSection {
  position: relative;
}

.itemPage .itemEditSection .samOverlay {
  left: 10px;
  right: unset;
  top: 50px;
}

.itemPage .itemEditSection .editContainer,
.itemPage .itemEditSection .deleteContainer {
  display: inline-block;
}


.whiteText, .whiteText:hover {
  color: white;
}

.whiteTextLink {
  color: #ddd;
  transition: color var(--transitionTime);
  cursor: pointer;
}
.whiteTextLink:hover {
  text-decoration: none;
  color: white;
}

.tabBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 40px;
}

.tabBar.slim{
	margin-top:5px;
}

.tabBar a {
	text-decoration: none;
	flex:1;
	display: flex;
	justify-content: center;
}



.tabContainer {
  color: grey;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: color var(--transitionTime);
  margin-bottom: 10px;
  flex:1;
}

.tabContainer.active {
  color: white;
}

.tabContainer .tabUnderline {
  height: 2px;
  border-radius: 10px;
  background-color: white;
  margin-top: 10px;
  transition: all var(--transitionTime);
  width: 0%;
  opacity: 0;
}

.tabContainer.active .tabUnderline {
  width: 100%;
  opacity: 1;
}



/* Account page */
.addShippingAddressSection h5 {
  margin-top: 50px;
}

.paypalSignupSection {
  border: solid 1px #666;
  border-radius: var(--borderRadius);
  padding: 10px;
  margin-bottom: 10px;
}

.accountPage button {
  margin-left: 0;
}

#accountPageTitle {
  margin: 18px 0 20px 0;
  font-size: 1.8rem;
}


/* Cart page */

.allCartItems {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cartItem {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.cartItem .itemImage {
  width: 90px;
  height: 90px;
  border-radius: var(--borderRadius);
}

@media screen and (min-width: 1000px) {
  .cartItem {
    width: 700px;
  }

  /* .cartItem .itemImage {
    width: 500px;
    height: 300px;
  } */
}

.flex-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

span.headerSplit3.full {
    flex: 1;
}

.boldX{
    color: red;
    font-weight: bold;
}

.cartItem.unavailable {
  color: grey;
}

div.paypalButtonContainer {
  display: flex;
  justify-content: center;
}


/* admin */
.defaultPage.AdminPage, .defaultPage.OwnerPage, .defaultPage.ModPage {
  max-width: none;
}
.adminHomeGroup {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.adminHomeGroup a {
  display: flex;
}
.adminGroupButton {
  border: solid 1px #444;
  border-radius: var(--borderRadius);
  padding: 20px;
  width: 200px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ccc;
  cursor: pointer;
  transition: color var(--transitionTime),
                border-color var(--transitionTime),
                background-color var(--transitionTime);
  background-color: #181818;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.adminGroupButton:hover {
  color: white;
  border-color: #ccc;
  background-color: #111;
}

.AdminPage a:hover {
  text-decoration: none;
}

.adminUsersPage {
  padding: 30px;
}

.AdminPage .userRow {
  cursor: pointer;
}

.AdminPage button.active {
  color: white;
  border-color: white;
}

.AdminViewItemPage .itemImage,
.AdminViewPendingItemPage .itemImage
 {
  width: 400px;
  margin: 10px;
}

.AdminPage .displayItemPage {
  padding: 30px;
}

.AdminPage .noDataFoundMessage {
  text-align: center;
}

.clickableRow {
  cursor: pointer;
}

.adminImage {
  width: 400px;
  height: 400px;
  object-fit: contain;
}

.adminListImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
}


#adminChosenUserItemsContainer {
  display: flex;
  flex-wrap: wrap;
}

.addNewCountrySection {
  margin-bottom: 40px;
}

.AdminPage .countryRow .countryNameSection {
  display: inline-block;
}

.AdminPage .countryRow .dropzone {
  display: inline-block;
  top: -7px;
}

.AdminPage .countryRow .dropzoneSection {
  display: inline-block;
  margin: 0 10px;
}

.AdminPage .countryRow .dropzone .imageUploadBox,
.AdminPage .countryRow .dropzone .emptyImageUploadBox{
  width: 80px;
  height: 80px;
}

.AdminPage .countryRow .countryHiddenButton{
  margin: 0 10px;
}

.AdminPage .countryRow .deleteCountryButton{
  margin: 0 10px;
}

.AdminPage .countryRow .dropzone .xIcon{
  width: 30px;
  height: 30px;
}

.AdminPage .countryRow .existingFlagImageSection {
  display: inline-block;
  width: 282px;
  text-align: center;
  margin: 0 10px;
}

.AdminPage .countryRow .existingFlagImageSection .existingFlagImagePreview {
  width: 77px;
  height: 77px;
  object-fit: cover;
  margin-right: 5px;
}
.AdminPage .totalCountText {
  margin: 12px 0;
}

/* End Admin */


/* Item Snippet */
.itemSnippet {
  color: #ddd;
  transition: color var(--transitionTime);
  flex: 33%;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}


.itemSnippetContainer .itemName{
	color: #FFF;
    font-size: 1.1rem;
}

.itemSnippetContainer .itemDivider{
	padding:0px 15px;
}

.itemSnippet a {
  color: #ddd;
  text-decoration: none;
}
.itemSnippet:hover, .itemSnippet:hover a {
  text-decoration: none;
  color: white;
}
.AdminPage .itemSnippet .itemSnippetContainer {
  margin: 20px;
  margin-bottom: 50px;
  cursor: pointer;
}

.itemSnippet .itemSnippetContainer {
  cursor: pointer;
}

.itemSnippet.itemConvoType {
  display: block;
}

.itemSnippet.itemConvoType .itemSnippetContainer {
  display: flex;
}

.itemSnippet .textSection {
  padding-left: 10px;
}

.itemSnippetImageDisplay {
  object-fit: cover;
  border-radius: var(--borderRadius);
  /*transition: width 0.3s, height 0.3s;*/
}

.itemSnippetLineOne, .itemSnippetLineTwo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.itemSnippet .heartIcon {
  width: 1.5rem;
  height: 1.5rem;
}

/* End Item Snippet */



/* Feed */

.feedPage .LInput::placeholder {
  opacity: 0.5;
}

.feedPage .newPostContainer {
    margin: 10px;
    text-align: center;
    padding: 5px 5px 1px;
}

.feedPage .newPostContainer .postContainer{
	background:var(--fadedGray);
}

.feedPostWrapper {
    background: #0e0e0e;
	padding-bottom:10px;
}

.feedPage textarea {
  border: none;
  resize:none;
}


.feedPostsContainer a,
.feedPostsContainer .postContainer a
{
  text-decoration: none;
  /* color: #ccc; */
  transition: color var(--transitionTime);
}
.feedPostsContainer .postContainer:hover a {
  color: white;
}

span.taggedUser a:hover{
	color:#5299f5;
}

.imageFeedContainer{
	text-align:center;
	padding-top:12px;
}

.whiteOnHoverText {
  transition: color var(--transitionTime);
}

.postContainer .header-feed, .postContainer .postBody{
	padding: 20px 10px 0px 10px;
}


.postContainer .header-feed .center-info, .postsHeader .centerInfo{
	flex:1;
	margin-left: 12px;
}

.center-info a, .centerInfo a{
	display:flex;
}

.feedPostsContainer .postContainer {
  border-radius: var(--borderRadius);
  margin-top: 30px;
  transition: border-color var(--transitionTime);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.feedPage .imageUploadBox, .feedPage .emptyImageUploadBox {
  width: 80px;
  height: 80px;
}

.addPhotoText{
	opacity:.75;
	font-size:.8rem;
	margin-top:6px;
}

.feedPage svg {
  color: #ccc;
  transition: color var(--transitionTime);
  cursor: pointer;
}

.feedPostsContainer .postContainer:hover,
.feedPage svg:hover {
  border-color: white;
  color: white;
}


.feedImageDisplay {
  width: 100%;
  height: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: width var(--transitionTime);
  margin-top: 0px;
  border-radius: 0;
}

.feedButtonActions{
	cursor:pointer;
	display:flex;
}

button.copyURL_button{
	border:none;
}

button.copyURL_button svg{
	width: 24px;
	height:24px;
}

.feedPostsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.feedPost {
  width: 100vw;
  margin-bottom: 30px;
}

.feedPage .feedPostDivider {
  width: calc(100vw - 20px);
}

.feedImageContainer {
  text-align: center;
  padding: 12px 0px;
}

.feedUsername {
  /*font-weight: bold;*/
  color: white;
  font-size: 1rem;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
  display: inline-block;
}
.feedDate {
  font-size: 0.9rem;
  color: #939393;
}

.feedBelowImage {
	display: block;
    padding: 0px 10px;
}

.feedBelowImage .feedButtonBar {
  display: flex;
  flex-direction: row;
  padding: 2px;
}

.feedBelowImage .feedButtonBar .actionButton{
	margin:0px 16px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.feedBelowImage .feedButtonBar .actionButton:first-child {
	margin-left:0;
}

.feedBelowImage .feedButtonBar .actionButton svg, .likeHolder svg{
	width: 1.2rem;
    height: 1.2rem;
}

.feedButtonActions .storeIcon{
	height:22px;
	width:22px;
}

.feedBelowImage .feedButtonBar svg {
  margin-right: 8px;
}

.feedNestedComment .commentBody, .feedNestedComment .bottomCommentBar, .feedComment .commentBody, .feedComment .bottomCommentBar{
	margin-left:64px;
	margin-right:20px;
}


.bottomCommentBar{
	margin-top: 4px;
	font-size: .9rem;
	color: grey;
	display:flex;
}

.quickCommentInput textarea {
  margin: 10px;
  width: calc(100% - 20px);
}

.quickCommentInput button {
  margin: 10px;
  width: calc(100% - 20px);
}

.postSnippetCommentHeaderTextContainer {
  margin: 10px;
}


.postSnippetComment {
  padding: 13px 10px;
}

.quickCommentOtherCommentsText {
  margin: 10px;
  text-align: center;
}


/* End Feed */



/*.feedPost */

.postPage {
  /* extra space for @ tag suggestions */
  margin-bottom: 300px;
}

.postPage .postImage {
  width: 480px;
  max-width: 100%;
  margin: 0px;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}


.samTooltip hr {
  margin: 10px auto;
  width:75%;
  border-color: #FFF;
  opacity:.1;
}

.samTooltip {
  padding: 10px;
  text-align: center;
  font-size: 0.9rem;
}

.samOverlay {
  position: absolute;
  top: 0px;
  right: 30px;
  min-width: 125px;
  background-color: var(--deepGray);
  border-radius: var(--borderRadius);
}

.postPage .imageContainer {
  text-align: center;
}

.feedComment, .feedNestedComment {
    margin:0px;
	margin-left: 30px;
	padding:10px 0px;
	border-bottom: 1px solid var(--fadedGray);
}

.feedNestedComment {
    margin-left: 60px;
}

.postBodyCont{
	padding:2px 10px;
}

.postBodyCont .bottomCommentBar{
	border-bottom: 1px solid var(--fadedGray);
	padding-bottom:15px;
}

.postPage .replyInput, .postPage .nestedCommentInput {
  margin-top: 10px;
}

.commentOwner {
  color: grey;
}

.highlightedComment {
  border-color: #b00;
  background-color: #111;
}


/* modal
.modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  background-color: #00000020;

}*/


/* all conversations */


/* conversations */



.conversationButton {
  /* border: solid 1px #666; */
  border-radius: var(--borderRadius);

  transition: border-color var(--transitionTime), background-color var(--transitionTime);
  /* display: flex;
  align-items: center; */
}

.conversationButton, .itemConversationButton{
	margin: 6px 0px;
	padding: 4px;
}

.itemConversationButton.active, .conversationButton.active {
  background: var(--blue2);
}

.messageAndTimeContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.conversationButton .latestMessageSection {
  padding-left: 42px;
  color: grey;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.conversationButton.active .latestMessageSection {
  color: white;
}

.conversationButton .timeSection {
  text-align: right;
  white-space: nowrap;
  padding-left: 20px;
}

.conversationButton a {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  align-items: center;
}

.itemConversationButton .flexItemHeader{
	display: flex;
    width: 100%;
    align-items: center;
}

.itemConversationButton {
  /* border: solid 1px #666; */
  border-radius: var(--borderRadius);
  transition: border-color var(--transitionTime), background-color var(--transitionTime);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  margin: 25px auto;
}

.conversationButton{
	max-width: 500px;
    margin: 25px auto;
}


.itemConversationButton .userSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}


.conversationButton:hover, .itemConversationButton:hover {
  /* border-color: #ccc; */
  background-color: #1a1a1a;
  cursor: pointer;
}


.conversationButton.active, .itemConversationButton.active {
    border-color: white;
}

.itemConversationButton .itemSnippet {
  margin: 10px 0 0 0;
  color: grey;
}

.itemConversationButton.active .itemSnippet {
  color: white;
}


.messageImage {
  width: 100%;
}

.messageContainer.ownMessage {
  text-align: right;
}


.messageContainer .messageBodyContainer {
  width: calc(100% - 70px);
}

.messageContainer.ownMessage .messageBodyContainer {
  width: 100%;
}


.messageContainer .message {
    max-width:70%;
}

.messageContainer.ownMessage .message {
  width: 70%;
  background-color: var(--blue2);
}

.messageContainer.hasImages .message {
  width: 500px;
  background: none;
  border-radius: var(--borderRadius);
}

.messageContainer .userSnippet {
  margin-top: 10px;
}

.messageTime {
  padding-right: 15px;
  padding-left: 15px;
  color: grey;
  font-size: 0.9rem;
}
.messageContainer {
  margin-bottom: 5px;
}


.conversationName {
  background-color: var(--backgroundColor);
  padding: 10px;
  z-index: 20;
  top: 65px;
  max-width: var(--maxPageWidth);
  margin: 0px auto;
}

.conversationName .userSection, .conversationName .userSection a{
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.conversationName .itemSnippet {
  margin: 10px 10px 0 10px;
}


.conversationName .timeSection {
  width: 100%;
  text-align: right;
  padding-right: 10px;
  display: none;
}





.requestResponseArea {
  background-color: var(--backgroundColor);
  padding: 10px 10px 10px 10px;
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  text-align: center;
}

.newMessageArea {
  background-color: var(--backgroundColor);
  padding: 10px 10px 10px 10px;
  position: fixed;
  bottom: 65px;
  z-index: 50;
  left: 0;
  right: 0;
  text-align: center;
}

.sendMessage{
	background:var(--blue2);
	border:none;
	border-radius: 100px;
	padding:0px;
	min-width:35px;
	min-height:35px;
	display:flex;
	justify-content:center;
	align-items:center;
}

.modal-body input{
	border:solid 1px #444;
	background: var(--grayBorder);
}

.conversationPage .inputSection .dropzone .imageUploadBox, .conversationPage .inputSection .dropzone .emptyImageUploadBox{
	width:45px;
	height:45px;
	margin:0px 20px;
}

.conversationPage .inputSection .dropzone .addPhotoText{
	display:none;
}

.sendMessage svg{
	width:16px;
	height:16px;
}

.newMessageArea  .inputSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.newMessageArea .inputSection button {
  margin: 0;
}

.backButton{
	padding:10px;
}

.backButton .goBack{
	width:10px;
	height:10px;
	background:none;
	display:block;
	border-left:3px solid white;
	border-bottom:3px solid white;
	transform: rotate(45deg);
}

.newMessageArea  .inputSection #newMessageInput {
  flex:1;
  background: transparent;
  border:none !important;
  resize:none;
  border-radius: var(--borderRadius);
  padding: 5px 15px;
  color: white;
}

.newMessageArea .reviewSection {
	margin-top: 12px;
    font-size: .9rem;
	padding: 10px 0px;
	background: #282828;
    border-top: 1px solid var(--grayBorder);
    border-bottom: 1px solid var(--grayBorder);
}


.messages {
  margin-bottom: 80px;
}

.postText, .commentBody{
	margin: 10px 0px;
    display: block;
}

.bottomCommentBar .barItem {
    margin-right: 20px;
	display:inline-flex;
	align-items:center;
	cursor:pointer;
}

.bottomCommentBar .likeHolder .count{
	margin-left:5px;
}

.message {
  border: none;
  border-radius: var(--extraRoundBorder);
  text-align: left;
  margin: 10px;
  margin-bottom: 5px;
  background-color: #303030;
  display: inline-block;
  padding: 10px;
}

.conversationPage .imageUploadBoxContainer {
  position: relative;
  width: 100px;
  display: inline-block;
}

.conversationPage .imageCounterOverlay {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: red;
  border-radius: 20px;
  width: 1.6rem;
  height: 1.6rem;
}

/* Reviews */
.reviewItemNameContainer {
   margin: 10px;
}

.reviewRatingStars {
  margin: 10px;
}

.reviewTextBody {
  margin: 10px;
}

/**MOBILE **/
@media screen and (max-width: 500px) {
  .messageContainer {
    font-size: 0.9rem;
  }

  .userSnippet .spaceBetween .textAlignRight{
	display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 3px 0px;
  }

  .userSnippet .spaceBetween .textAlignRight span{
	position:static !important;
  }
}



/* edit item */
.editItemImageDeleteButton {
  /* background-color: rgba(0, 0, 0, 0.85);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin: 0px;
  padding: 0px; */
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
/* .editItemImageDeleteButton:hover {
  background-color: rgba(0, 0, 0, 1);
  font-weight: bold;
} */

.editItemImageDeleteButton span {
  position: relative;
  top: -3px;
  left: 4px;
}

.editItemImageDeleteButton .icon {
  width: 20px;
  height: 20px;
}

.TermsPage ol{
	font-size:1.4rem;
}

.TermsPage ul li{
	margin:5px 0px 5px 0px;
}

.terms-links{
	margin:1rem 0px 1rem 0px;
}

.JustTextPage h1, .JustTextPage h2, .JustTextPage h3, .JustTextPage h4{
	margin:15px 0px 25px 0px;
}

.JustTextPage ol{
	margin:1rem 0px 1rem 0px;
}

.JustTextPage a{
	color:var(--softBlue);
}

/* notifications */

.notifProfilePic {
	margin:0px 6px;
	display: inline-block;
}

.notifContent {
  display: inline-block;
  flex: 1;
}

.notifContent .username {
    font-weight: 500;
    margin-right: 0.2rem;
}

.notifAction {
    margin-left: 6px;
}

.notification .notifAction button{
	font-size:.9em;
	padding:2px 5px;
}

.notification {
  border-radius: var(--borderRadius);
    margin: 10px 4px;
    padding: 4px 2px;
  text-align: left;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size:.95em;
}

.notification .notifMainContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.notification .notifTime {
  color: #bbb;
}

.notification.active {
	background: var(--blue2);
}

.notification .postSnippetImage {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: var(--borderRadius);
}


/* Sales Page */

.salesFirstLine {
  display: flex;
}

.salesImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  transition: width var(--transitionTime),
              height var(--transitionTime),
              left var(--transitionTime),
              top var(--transitionTime);
  border-radius: var(--borderRadius);
  margin-bottom: 10px;
}

.salesRightOfImage {
  display: inline-block;
  margin-left: 10px;
  font-size: 0.88rem;
  width: calc(100% - 90px);
}


.requestFeedbackButton {
  padding-top: 10px;
  opacity: 0;
}


/* Purchases */


.purchasesPage .LInput::placeholder, .salesPage .LInput::placeholder {
  opacity: 0.5;
}




/* contact and about pages */

.contactPage #contactTitle, .aboutPage #aboutTitle {
  margin-top: 40px;
  margin-bottom: 30px;
}

.aboutPage #aboutBody, .contactPage #contactBody {
  margin: 10px;
}


/* @ tags */
.atTagSuggestions {
  background-color: var(--deepGray);
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  padding: 10px;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
}
.atTagSuggestions.topAtTagSuggestions {
  bottom: 0;
}
.atTagSuggestions.bottomAtTagSuggestions {
  top: 0;
}

.tagSuggestedUser .userSnippet .profilePictureBackground{
	margin-right:10px;
}

.textItself {
    overflow: hidden;
    align-self: center;
    text-overflow: ellipsis;
    width: 100%;
    padding: 2px;
    margin: auto;
    display: block;
}

.feedPostsGrid .gridItem{
    display: inline-flex;
	width: calc(33% - 2px);
	height: 0;
	margin: 0;
    padding: 0;
    padding-bottom: calc(33% - 2px);
    margin:0px 3px 3px 0px;
	position: relative;
	cursor:pointer;
}


.explorePage .exploreRow {
	width: 100%;
	max-height: 200px;
	margin: 0;
    padding: 0;
	height: calc(33.333vw);
}

.explorePage .exploreRow.large {
	max-height: 400px;
	height: calc(66.666vw);
}

.explorePage .exploreItem{
	flex:1;
	display: inline-flex;
	margin:0px 3px 3px 0px;
	position: relative;
	cursor:pointer;
	width: auto;
    height: auto;
}

.homeItem.count0, .homeItem.count2{
	margin-right:6px;
}

.homeBottomRow {
    margin-bottom: 12px;
	position: relative;
}

button.semiTransparent{
	opacity:.5;
}

.homeBottomRow .likeItemBox.secondItem{
	position:absolute;
	top:-45px;
	right:0px;
	margin:5px;
	border-radius: 50px;
	display:flex;
	background:#000000b8;
	justify-content: center;
	align-items:center;
	height:30px;
	width:30px;
}

.homeItem{
    width: calc(50% - 3px);
	/*
    padding-bottom: 50%;
    height: 0;*/
	display: inline-block;
}

.useMaxWidth{
	max-width: var(--maxWidthNarrow);
	margin-left: auto;
    margin-right: auto;
}

.itemPage .bio{
	margin:10px;
}
.narrowView{
	width:100%;
	padding-bottom:0 !important;
}

.explorePage .wide.exploreCol{
	flex:2;
}

.feedPostsGrid.listView, .feedPostsContainer.gridView{
	display:none;
}

.feedPostsContainer .postContainer:first-of-type {
	margin-top:0;
}

.feedPostsGrid .imgItem, .homeImageDisplayDiv{
	display: inline-block;
    background-size: cover;
    background-position: center center;
}

.feedPostsGrid .textItem{
	border: 1px solid var(--deepGray);
    box-sizing: content-box;
}

.feedPostsGrid .feedRow, .explorePage .exploreRow{
    display: flex;
	position:relative;
}

.explorePage .exploreRow.backwards{
	flex-direction: row-reverse;
}

.explorePage .exploreCol {
    display: flex;
    flex-direction: column;
	flex:1;
}

.feedPostsGrid .gridItem .textInner {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    font-size: 15px;
}

.postsHeader{
	margin:10px 10px 0px 10px;
	display: flex;
	justify-content: space-between;
}

.postsHeader .popup{
    flex: 1;
    display: flex;
    justify-content: flex-end;
	position:relative;
}

.atTagSuggestionsContainer {
  position: relative;
  z-index: 100;
}

.tagSuggestedUser {
  padding: 10px;
  display: flex;
  align-items: center;
  transition: background-color var(--transitionTime);
}

.tagSuggestedUser:hover {
  background-color: #111;
}


/* Dropzone */

.dropzone {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  position: relative;
}
.dropzone:focus {
  outline: none;
}

.dropzone .overlayEditButtons {
  top: 10px;
  right: 10px;
  margin: 0;
}

.dropzone .imageCounterOverlay {
  position: absolute;
  left: 14px;
  top: 14px;
  background-color: red;
  border-radius: 20px;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.85rem;
}


/* Edit Page */
.editPage .dropzoneContainer {
  display: flex;
  justify-content: center;
}

span.manageButtonContainer{
    display: flex;
    margin: 10px;
}

.filterContainer{
	display: flex;
    margin: 10px;
}

.filterContainer svg{
	height:20px;
	width:20px;
}

.manageIconContainer{
  background: #FFF;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
	margin-right:10px;
	cursor:pointer;
}


/* Seller App page */
.SellerApplicationPage .dropzone {
  display: block;
}

/* Following page */
.followingPage .showAllText {
  cursor: pointer;
  text-align: center;
}



.feedPage  .postPage{
  padding-bottom: 100px;
}

.SellerApplicationPage, .paypalSignupPage, .homePage, .joinUsPage,
.signInPage, .reviewsPage, .purchasesPage, .addNewItemPage, .allConversationPage, .allConversationsPage,
.explorePage, .notificationPage, .itemPage, .feedPage, .manageItemsPage,
.cartPage, .AdminPage, .OwnerPage, .ModPage,  .conversationPage,
.followingPage  /*.accountPage*/  {
  padding-bottom: 100px;
  padding: 0 5px;
}


.itemPage, .manageItemsPage, .homePage {
  padding: 0 5px;
}

/* .addNewItemPage, .accountPage, .SellerApplicationPage, .paypalSignupPage,
.joinUsPage, .signInPage {
  padding: 0 15px;
} */

.explorePage {
  padding: 0px;
}

.defaultPage {
  padding-bottom: 100px;
  max-width: var(--maxWidthNarrow);
  margin-left: auto;
  margin-right: auto;
}

.defaultPage.accountPage {
  /* make sure all countries are selectable in account page */
  padding-bottom: 180px;
}

.defaultPage.homePage{
	max-width: var(--maxPageWidth);
}

.messageTextContainer {
    text-overflow: ellipsis;
    overflow: hidden;
	white-space: pre-line;
}

.allhomeItemsContainer{
	overflow:visible !important;
}

.bigPageWrapper {
    display: flex;
    flex-direction: column;
	max-width: var(--maxPageWidth);
	margin-left: auto;
	margin-right: auto;
	padding-bottom:100px;
}

.signupWrapper{
	padding:0px 10px;
	width:100%;
}

.joinUsPage, .notificationPage, .allConversationsPage {
	max-width: var(--maxWidthNarrow);
}


/* desktop screen size */
@media screen and (min-width: 1200px) {
  .removeScrollParent {
    width: var(--maxPageWidth);
  }

  .manageItem {
    width: 600px;
  }

  .manageItemsImage, .userStoreItemImage {
    width: 500px;
    height: 300px;
  }

  .homeItem{
	 width: calc(25% - 6px);
   /*  padding-bottom: 25%;*/
  }

  .homeItem.count0, .homeItem.count1, .homeItem.count2{
	  margin-right:6px;
  }
}


@media screen and (min-width: 800px) {
  .feedPost {
    width: 600px;
  }

  .feedPage .feedPostDivider {
    width: 580px;
  }
}


/* nav mobile vs desktop */


@media screen and (max-width: 991px) {
  /* mobile size */
  .navHideOnMobile {
    display: none;
  }

}

@media screen and (min-width: 992px) {
  /* desktop size */
  .navShowOnMobile {
    display: none;
  }
}

/*ultra narrow mobile */
@media screen and (max-width: 600px) {
	.bottomNavBar .icon.homeIcon{
		height:30px;
	}


	.bottomNavBar .icon.searchIcon {
		height: 20px;
	}

	.bottomNavBar a svg{
		height:28px;
	}

	.bottomNavBar a img.feedIcon, .bottomNavBar a img.newItemIcon{
		height:30px;
	}

	.bottomNavBar .profilePictureBackground{
		height:28px;
		width:28px;
	}

}
